module.exports = {
    name: 'Hybrid Training',
    logo: 'https://img.genflow.com/hybrid/checkout/logo.png',
    vendorUUID: 'b4526bce-c554-490a-ba4a-8e7054ddd149',
    metaData: {
        title: 'Hybrid Training',
        description: 'Willkommen bei Hybrid Training.',
        author: 'GENFLOW',
        url: 'https://hybridtraining.de/',
        image: 'https://img.genflow.com/hybrid/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#ce1b1b',
            text: '#697386',
            ctaText: '#ffffff',
        },
    },
    analytics: {
        gtag: 'G-WZMSRLS3H3',
        facebookPixel: '1091538311495920',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://hybridtraining.de/terms-conditions',
        privacy: 'https://hybridtraining.de/privacy-policy',
        support: 'https://support.hybridtraining.de/',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/hybrid-training/id1550616002',
        android: 'https://play.google.com/store/apps/details?id=de.idonos.hybrid_training&hl=en&gl=US',
    },
    forms: {
        errors: {
            existingUser: 'Dieser Benutzer existiert bereits, bitte melde dich an.',
            invalidPassword: 'Das Passwort muss 6 oder mehr Zeichen enthalten.',
            unknownError: 'Die Registrierung konnte nicht verarbeitet werden.',
            userNotFound: 'Falsche Email/Passwort-Kombination',
        },
        translations: {
            password: 'Passwort',
            alternativeTextRegister: 'Du hast bereits einen Account?',
            alternativeTextLogin: 'Du hast noch keinen Account?',
            alternativeCtaTextRegister: 'Login',
            alternativeCtaTextLogin: 'Registrieren',
            forgotPassword: 'Passwort vergessen?',
            privacyPolicy: 'Datenschutzerklärung',
            privacyPolicyPreText: 'Durch',
            privacyPolicyMidText: 'stimmst du unserer',
            privacyPolicyPostText: ' zu.',
            needHelp: 'Benötigst du Hilfe? Besuche unser',
        }
    },
    pages: {
        register: {
            title: 'Anmelden bei',
            ctaText: 'Registrieren',
            ctaLoadingText: 'Registrieren',
        },
        login: {
            title: 'Einloggen in',
            ctaText: 'Login',
            ctaLoadingText: 'Einloggen',
        },
        manageSubscription: {
            translations: {
                headingPreText: 'Du hast ein aktives',
                headingMidText: 'Abo für',
                usernameReminder: 'Dein Nutzer ist',
                periodFinishes: 'Aktueller Abozeitraum endet am',
                startedOn: 'Gestartet am',
                appStoreCancelText: 'Wenn du dein Abo über den App Store oder Play Store abgeschlossen hast und dieses kündigen möchtest, musst du das über den jeweiligen Store tun.',
                logout: 'Logout',
                cancelConfirmation: 'Du hast dein Abonnement gekündigt. Es wird zum Ende der Laufzeit auslaufen.',
                cancelCta: 'Abonnement kündigen',
                cancelWebPreText: 'Wenn du dein Abonnement über die Webseite abgeschlossen hast, kontaktiere bitte unser',
                cancelWebMidText: 'Support Center',
                cancellationReconfirm: 'Bist du sicher, dass du dein Abonnement kündigen möchtest?',
                cancellationReconfirmYes: 'Ja, bitte.',
                cancellationReconfirmNo: 'Nein!',
                cancellationDelayText: 'Es kann bis zu 2 Stunden dauern, bis es gekündigt ist.',
                cancellationDelayLongText: 'Dein Abonnement wurde gekündigt. Es kann bis zu 2 Stunden dauern, bis es hier angezeigt wird.',
                cancellationError: 'Es gab einen Fehler bei der Kündigung deines Abonnements. Bitte kontaktiere unser Support Center.',
            },
        },
        subscribe: {
            translations: {
                headingPreText: 'Wähle deine',
                headingPostText: 'Abo-Variante',
                agreementPreText: 'Durch die Wahl eines Abonnementplans akzeptierst du unsere',
                privacyPolicy: 'Datenschutzerklärung',
                agreementMidText: 'und',
                terms: 'AGB',
                needHelp: 'Benötigst du Hilfe? Besuche unser',
                logout: 'Logout',
            },
        },
        forgotPassword: {
            translations: {
                heading: 'Passwort vergessen?',
                instructions: 'Bitte gib unten deine E-Mail Adresse ein und wir schicken dir eine Mail mit einem Link zum Zurücksetzen deines Passworts.',
                reset: 'Zurücksetzen',
                invalidEmailError: 'Bitte gib unten deine E-Mail Adresse ein und wir',
                emptyEmailError: 'Bitte gib deine E-Mail Adresse ein.',
                somethingWentWrong: 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut.',
                successPreText: 'Wir haben dir eine Mail mit der Anleitung zum Zurücksetzen des Passworts an',
                successPostText: ' geschickt.',
            },
        },
        resetPassword: {
            translations: {
                heading: 'Passwort zurücksetzen',
                instructions: 'Bitte gib unten dein neues Passwort ein.',
                newPassword: 'Neues Passwort',
                confirmPassword: 'Neues Passwort bestätigen',
                reset: 'Passwort zurücksetzen',
                emptyPasswordError: 'In beiden Feldern müssen Sie Ihr Passwort eingeben.',
                passwordsMathingError: 'Die Passwörter müssen übereinstimmen.',
                passwordLengthError: 'Das Passwort muss 6 oder mehr Zeichen enthalten.',
            },
        },
        thankyou: {
            translations: {
                heading: 'Abo bestätigt',
                downloadText: 'Lade dir die App herunter und logge dich mit dem selben Konto ein, das du soeben erstellt hast.',
                needHelp: 'Benötigst du Hilfe?',
                supportCenter: 'Besuche das Support Center',
            },
        },
    },
    products: [
        {
            title: '1 Monat',
            product: '1month',
            // coupon: 'RVh7aWEa',
            // label: 'BLACK FRIDAY: 13% ERSPARNIS',
        },
        {
            title: '3 Monate',
            product: '3month',
            // coupon: '4J0UkfyJ',
            // label: '20% ERSPARNIS',
        },
        {
            title: '12 Monate',
            product: '12month',
            // coupon: 'PQ55doum',
            // strikethroughLabel: '36% OFF',
            // label: '25% OFF',
        },
    ],
    offers: [
        {
            id: 'winback',
            products: [
                {
                    title: '12 Monate',
                    product: '12month',
                    coupon: 'ayxTjhrk'
                }
            ]
        }
    ]
};
